<template>
  <section id="home">
    <div class="container width">
      <div class="d-flex justify-content-start flex-column text-justify">
        <!-- <div class="h5 home-heading item-hl text-center fw-bold">Summary</div> -->
        <!-- <div class="h5 home-heading item-hl text-center fw-bold">
          Dylan is very dumb
        </div> -->

        <div id="summary" class="px-4 mb-3 item-hl text-left">
          graduated from the University of Pittburgh with a Bachelor's Degree in
          Compputer Science. I am experienced in many areas in Softare
          Development such as front end, back end, database mangement and
          testing. I have extensive experience in developing with Java,
          Javascript and Vue.js 3. This website is website is hosted on AWS and
          was developed using Vue.js 3 and Bootstrap 5.
        </div>

        <div class="home-heading h5 mb-2 mt-4 item-hl text-center fw-bold">
          Skills
        </div>
        <ul class="list-group text-left">
          <li class="list-group-item list-group-item-primary">
            <strong>Programming Languages</strong> - Java, C, C++, Javascript,
            Typescript, Python, PL/pgSQL
          </li>
          <li class="list-group-item list-group-item-primary">
            <strong>Frameworks/Libaries </strong>- Vue.js, Node.js, Flask,
            Bootstrap, REST APIs, AJAX, JPA
          </li>
          <li class="list-group-item list-group-item-primary">
            Experienced in <strong>relational databases</strong> such as
            <strong>PostgresSQL</strong> and Oracle. I have experience in
            writing functions, procedures, queries, prepared statements, views,
            triggers, etc.
          </li>
          <li class="list-group-item list-group-item-primary">
            Developing Web Applications using Vue 3, Java, PostgresSQL
          </li>
          <li class="list-group-item list-group-item-primary">
            <strong>Testing</strong> - Have experience in JUnit testing and
            Katalon/Selenium(web testing); Able to write code stubs, mocks, test
            doubles in JUnit
          </li>
          <li class="list-group-item list-group-item-primary">
            <Strong>Software/Tools </Strong>- IntelliJ, VS Code, DataGrip,
            Git/Github, Seleniun/Katalon
          </li>
        </ul>

        <div class="home-heading h5 mb-2 mt-4 text-center fw-bold">
          CS Courses Taken
        </div>
        <div class="container">
          <div class="row py-2">
            <div
              v-for="course in courses"
              :key="course"
              class="col-md-6 col-sm-12 text-left border"
              style="background-color: rgb(255, 241, 206)"
            >
              {{ course }}
            </div>
          </div>
        </div>
        <ul class="list-group mb-4">
          <li v-for="course in courses" :key="course" class="list-group-item">
            {{ course }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      courses: [
        "CS 401 Intermediate Programming Java",
        "CS 441 Discrete Structures in CS",
        "CS 447 Computer Organization & Assembly Language",
        "CS 449 Intro to Systems Software",
        "CS 1501 Algorithm Implementation",
        "CS 1520 Programming Languages for Web Applications",
        "CS 1550 Intro to Operating Systems",
        "CS 1555 Database Management Systems",
        "CS 1632 Software Quality Assurance",
        "CS 1652 Data Communication and Computer Networks",
      ],
    };
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .width {
    max-width: 65% !important;
  }
}
</style>
