<template>
  <section id="project">
    <div class="container width">
      <div class="d-flex justify-content-start flex-column">
        <div class="proj-heading h5 mb-2 item-hl">Algorithm Implementation (Java)</div>
        <ul class="proj-heading list-group">
          <li class="list-group-item list-group-item-primary">
            Project 1 - Implement a DLB to store Dicitionary words
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 2 - Implement LZW Compression
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 3 - Implment Priority Queues to create a basic app to help a user buy
            a car
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 4 - A program that analyzes a graph using Dijkstra, Prim, and Depth
            First Search
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 5 - Generate a 512-bit RSA keypair and store public/private keys and
            verify digital signatures using a SHA-256 hash
          </li>
        </ul>

        <div class="proj-heading h5 mb-2 mt-4 item-hl">
          Software Quality Assurance (Java)
        </div>
        <ul class="list-group proj-heading">
          <li class="list-group-item list-group-item-primary">
            Deliverable 1: Devloped a test plan and traceability matrix for a system.
          </li>
          <li class="list-group-item list-group-item-primary">
            Deliverable 2: Created unit tests for a console-based application such as
            mocks, stubs, and test doubles. (JUnit)
          </li>
          <li class="list-group-item list-group-item-primary">
            Deliverable 3: Developed a simple web application system with the help of
            automated systems testing. (Katalon)
          </li>
          <li class="list-group-item list-group-item-primary">
            Deliverable 4: Optimized a program using performance testing and pinning test
            using Visual VM
          </li>
          <li class="list-group-item list-group-item-primary">
            Deliverable 5: Developed a full-fledged GUI program using Test Driven
            Development, exploratory testing, automated unit testing, code coverage,
            manual unit testing, static testing, and model chcecking
          </li>
        </ul>

        <div class="proj-heading h5 mb-2 mt-4 item-hl">
          Database Management Systems (Java, PostgresSQL)
        </div>
        <ul class="list-group proj-heading">
          <li class="list-group-item list-group-item-primary">
            Project: PittSocial - Implment basic Social Media Program where you can add
            friends, send messages, and create groups using Java, PostgresSQL, PL/pgSQL
            and JDBC without a UI
          </li>
        </ul>

        <div class="proj-heading h5 mb-2 mt-4 item-hl">
          Privacy in Electronic Society (Java, Python)
        </div>
        <ul class="list-group proj-heading">
          <li class="list-group-item list-group-item-primary">
            Project 1: Developed an alogirthim that is senstive to its input fo
            differential timing attacks (Java)
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 2: Developed own Access Control Policy Language that supports
            indirection, administrative delegation, and intersection using Python to
            process the policies and JSON to store them
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 3: Developed an algorthim that transformed a chosen data set so that
            it satisfies k-anonimity and a variant alogirthm that satisfies differential
            privacy (Python)
          </li>
        </ul>

        <div class="proj-heading h5 mb-2 mt-4 item-hl">
          Web Applications (JavaScript, Python, Flask, HTML, CSS, SQLAlchemy)
        </div>
        <ul class="list-group proj-heading">
          <li class="list-group-item list-group-item-primary">
            Project 1 - Create <a href="battleship"> Battleship </a> by manipuing the DOM
            (JavaScript)
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 2 - Developed a Web Application to help a Catering Company using
            Python, Flask, SQLAlchemy, and the Flask-SQLAlchemy extension
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 3 - Developed a Website to host and manage Chat Rooms using AJAJ and
            JSON
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 4 - Developed a budget application using RESTful APIs and using
            functional Programming (JavaScript, Flask)
          </li>
        </ul>

        <div class="proj-heading h5 mb-2 mt-4 item-hl">
          Intro to Artificial Intelligence (Python)
        </div>
        <ul class="list-group proj-heading">
          <li class="list-group-item list-group-item-primary">
            Assignment 1 - Implement A* search, iterative deepening A*, and iterative
            depth first search
          </li>
          <li class="list-group-item list-group-item-primary">
            Assignment 2 - Implement expectimax algorithm for decision trees, created
            Markove Decision Process diagrams, and created a first order logic knowledge
            base of problem.
          </li>
          <li class="list-group-item list-group-item-primary">
            Assignment 3 - Implement a multinomial Naive Bayes classifier and a plannning
            algorithm
          </li>
        </ul>

        <div class="proj-heading h5 mb-2 mt-4 item-hl">Operating Systems (C)</div>
        <ul class="list-group proj-heading">
          <li class="list-group-item list-group-item-primary">
            Project 1: Implement Syscalls Down and Up using Semaphores
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 2: Implement Proccess Synchronization in the Tenant and Agent problem
            using Condition Variables and Locks
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 3: Virtual Memory Simulator - Implement a page table for a 32-bit
            address space with 4KB in size and implement Opt, FIFO, and Aging Algorithms
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 4: Implement a Basic File System
          </li>
        </ul>

        <div class="proj-heading h5 mb-2 mt-4 item-hl">
          Data Communications and Computer Networks (C)
        </div>
        <ul class="list-group proj-heading">
          <li class="list-group-item list-group-item-primary">
            Project 1: Develop a Web Client and Server (C/C++)
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 2: Implement TCP using Minet
          </li>
          <li class="list-group-item list-group-item-primary">
            Project 3: Implement Distance Vector Algorithm and Link State Algorithm in a
            Routing Simulator/li>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Projects",
};
</script>

<style scoped>
@media (min-width: 992px) {
  .width {
    max-width: 60% !important;
  }
}
</style>
