<template>
  <div class="hello">
    <nav
      class="navbar navbar-expand-lg navbar-light mb-3"
      style="background-color: #4da9ff"
    >
      <div class="container-fluid">
        <a class="navbar-brand fw-bold">Kevin Lau</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-2 mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                @click="setComponent('Home')"
                class="nav-link active pointer"
                aria-current="page"
                >Home</a
              >
            </li>
            <li class="nav-item active pointer">
              <a @click="setComponent('Projects')" class="nav-link active"
                >Projects</a
              >
            </li>
            <li class="nav-item active pointer">
              <a @click="setComponent('Contact')" class="nav-link active"
                >Contact</a
              >
            </li>
          </ul>
          <!-- <form class="d-flex">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> -->
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  props: {},
  methods: {
    setComponent(cmp) {
      // alert("hello");
      this.$emit("set-component", cmp);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pointer {
  cursor: pointer;
}
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
