<template>
  <footer id="footer" class="bg-light text-center text-lg-start mt-3">
    <!-- Copyright -->
    <div
      class="text-center p-3 fw-bold text-black"
      style="background-color: rgba(0, 0, 0, 0.3)"
    >
      Copyright © 2021 Kevin Lau
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
