<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
  <Navbar @set-component="setSelectedComponent"> </Navbar>
  <!-- <Home></Home>
  <Projects></Projects> -->
  <component :is="selectedComponent"></component>
  <Footer></Footer>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Home from "./components/Home.vue";
import Projects from "./components/Projects.vue";
import Contact from "./components/Contact.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Home,
    Projects,
    Contact,
    Footer,
  },
  data() {
    return {
      selectedComponent: "Home",
    };
  },
  methods: {
    setBackground: {
      // document.body.style.backgroundColor = "blue";
    },
    setSelectedComponent(cmp) {
      this.selectedComponent = cmp;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  background-color: rgb(255, 255, 255);
}
</style>
