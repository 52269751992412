<template>
  <section id="contact">
    <div class="d-flex justify-content-center flex-row text-justify">
      <div class="mb-3">
        <div class="fs-1 text-center fw-bold">Contact</div>
        <ul class="list-group">
          <li class="list-group-item list-group-item-info mt-4">
            <i class="far fa-envelope fa-2x fa-fw"> </i>
            <a class="" href="mailto:kevinlau2018@gmail.com">
              kevinlau2018@gmail.com</a
            >
          </li>
          <li class="list-group-item list-group-item-info">
            <i class="fas fa-phone-alt fa-2x fa-fw"></i>
            <a class="" href="tel:412-209-9416">412-209-9416</a>
          </li>
          <li class="list-group-item list-group-item-info">
            <i class="fab fa-linkedin fa-2x fa-fw"></i>
            <a href="https:www.linkedin.com/in/kevinmlau">
              www.linkedin.com/in/kevinmlau</a
            >
          </li>
          <li class="list-group-item list-group-item-info">
            <i class="fab fa-github fa-2x fa-fw"></i>
            <a class="" href="https:www.github.com/kevinlau11">
              www.github.com/kevinlau11</a
            >
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style scoped>
a {
  color: #118fd8 !important;
  text-decoration: none !important;
}

/* Big Screens  */
@media (min-width: 992px) {
  #contact {
    height: 582px !important;
    font-size: 1.3rem !important;
  }
}
/* Smaller Screens  */
@media (max-width: 991.98px) {
  #contact {
    height: 568px !important;
    font-size: 1.1rem !important;
  }
}
</style>
